.alertTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
}

.alertTable td, th {
  padding: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
}

.alertTable thead th {
  top: 0;
  background-color: #ed0f0f;
  color: #fff;
  font-size: 12px;
}

.alertTable tbody th:nth-child(even) {
  background-color: #f2f2f2;
}

.alertTable tr:nth-child(even) {
  background-color: #f2f2f2;
}


.alertTableHolder {
  padding-top: 10px;
}

.alertTableDivSearch {
  padding-top: 10px;
  margin-right: 10px;
}

.primary2 {
  justify-content: center;
  display: flex;
  max-height: 70vh;
  margin-bottom: 80px;
}
.primary22 {
  justify-content: center;
  display: flex;
  max-height: 70vh;
}

.primaryContainer2 {
  overflow: scroll;
  position: relative;
  width: 97%;
}

.tableHeader {
  overflow: scroll;
  position: relative;
  width: 97%;
  background: #e2e4e5;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tableLeftHolder {
  float: left;
  margin-left: 10px;
}
.tableRight {
  float: right;
  margin-right: 10px;
  top: 15px;
  position: relative;
  font-size: 13px;
}

.tableLeftBtn {
  background: #757575;
  padding: 5px;
  border-radius: 5px;
  color: white;
}

.alertTableSearchInput {
  width: 100%;
  max-width: 100px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  text-indent: 3px;
}

.alertSelectHolder {
  margin: 50px auto auto;
  padding: 10px;
  border-bottom: 1px solid;
}

.pageHeaderAlert {
  font-size: 2em;
  padding-left: 10px;
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.alertOptionsName {
  margin-top: 15px;
}

.alertBtnSearchHolder {
  margin-top: 20px;
  margin-bottom: 10px;
}

.alertBtnSearch {
  width: 20%;
  background-color: red;
  margin: 0 auto;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  font-size: 20px;
  color: white;
}
