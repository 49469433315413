@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


* {
  margin: 0;
  padding: 0;
}

body,
html {
  font-family: "Roboto", sans-serif;
  width: 100%;
 /* -webkit-touch-callout: none; /* iOS Safari */
/*   -webkit-user-select: none; /* Safari */
/*   -khtml-user-select: none; /* Konqueror HTML */
/*   -moz-user-select: none; /* Old versions of Firefox */
/*   -ms-user-select: none; /* Internet Explorer/Edge */
/*   user-select: none;*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

#root {
  /* overflow-x: hidden; */
}
#body2 {
  /* width: 100%;*/
}
#body.viewport-lg {
  position: absolute;
}

.appContainer{
  height: 100vh;
  overflow-y: scroll;
 
}
@media only screen and (max-width: 480px){
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    margin-bottom: 40px !important;
  }
}



@media screen and (min-width: 1000px) {
  .PupupMain-overlay {
    margin-left: 250px ;
    width: calc(100% - 250px) ;
  }
  .BottomFixPosition{
    position:-webkit-sticky  !important;
     position:sticky  !important;
     bottom: 0px  !important; 
  }


}

.BottomFixPosition{
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 150;
  right: 0px;
}


.web-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 10001;
  width: 90%; /* Use a percentage for width to make it responsive */
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding doesn't affect the width */

}

.mobile-content{
 width: 100%;
   height: 100vh;
  /*background: rgba(242, 241, 245);*/
  padding-bottom: 10px;
  background: #fff;

  -webkit-animation: anvil 0.3s cubic-bezier(0.938, 0.1, 0.36, 0.9);
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  max-height: 100vh  !important;
}



.popup-content {

  -webkit-animation: anvil 0.3s cubic-bezier(0.938, 0.1, 0.36, 0.9);
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  max-height: 80vh;

}


.holderTest {
  background: #fff;
  padding-bottom: 15px;
  z-index: 10;
  position: sticky;
  top: 0;
}


.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  position: relative;
  display: block;
  box-sizing: border-box;
  padding-top: 27px;
  padding-bottom: 10px;
  padding-left: 15px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #afafaf;
  appearance: none;
  background-color: white;
  font-size: 16px !important;
  color: black;
  resize: none;
  padding-right: 20px;
}


.popupContainerHolder {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  display: flex;
  z-index: 100;
  border-right: 1px solid #cbcbcb;
}

.PopupBottomHolder{
  width: 100%;
  padding-bottom: 30px;
  z-index: 100;
  background-color: #ffffff;
  justify-content: center;
  display: flex;
  
}


.holderPopupHeader{
  background: #fff;
  padding-bottom: 15px;
  z-index: 10;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #cbcbcb;
}

.DeletePopupButton {
  color: red !important;
}

.padding-right{
  margin-right: 10px;
}



.button-39 {
  background-color: #FFFFFF;
  border: 1px solid rgb(209, 213, 219);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #111827;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: center;
  -webkit-text-decoration: none #D1D5DB solid;
  text-decoration: none #D1D5DB solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  height: 50px;
  width: 40% 
}

.button-39:hover {
  background-color: rgb(249,250,251);
}

.button-39:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button-39:focus-visible {
  box-shadow: none;
}
.two-button {
  width: 40% !important;
}



